import jwtSign from 'jwt-encode';
import Cookies from 'js-cookie';

const signIn = async (credentials) => {
  try {
    let signInObject = {username : 'token', password : 'token'}
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = currentTime + 30 * 24 * 60 * 60; 
    signInObject.exp = expirationTime;
    
    const accessTokenDipsatcher = jwtSign(signInObject, process.env.REACT_APP_AUTH_GEN_KEY_Dispatcher, { expiresIn: expirationTime });
    const accessTokenExtractor = jwtSign(signInObject, process.env.REACT_APP_AUTH_GEN_KEY_Extractor, { expiresIn: expirationTime });
    const accessTokenDecision = jwtSign(signInObject, process.env.REACT_APP_AUTH_GEN_KEY_DECISION, { expiresIn: expirationTime });
    const accessTokenSapient = jwtSign(signInObject, process.env.REACT_APP_AUTH_GEN_KEY_SAPIENT, { expiresIn: expirationTime });
    const { username, password } = credentials;
    let scenario = "404";

    if (username === process.env.REACT_APP_BETA_USERNAME && password === process.env.REACT_APP_BETA_PASSWORD) scenario = "success";
    const resCode = {
      'success': { data: { accessTokenDipsatcher, accessTokenExtractor, accessTokenDecision, accessTokenSapient } },
      'denied': { response: { data: { code: 6010, message: 'Unauthorized' } } },
      '404': { response: { data: { code: 6004, message: 'Unauthorized' } } },
      'suspended': { response: { data: { code: 6003, message: 'Unauthorized' } } }
    };
    await new Promise((resolve, reject) => setTimeout(resolve, 1500));

    if (scenario !== 'success') throw (resCode[scenario]);

    const response = resCode[scenario];

    // Set cookies (simulate HTTP-only by not exposing to JavaScript)
    Cookies.set('customerId', process.env.REACT_APP_CUSTOMER_ID, { secure: true, sameSite: 'Strict' });

    return response;
  } catch (e) {
    throw e;
  }
};

const loginService = {
  signIn,
};

export default loginService;
