import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";

const UseLogout = () => {
  const navigate = useNavigate();
  const {
    setDispatcherToken,
    setExtractorToken,
    setDecisionToken,
    setSapientToken,
    setAuthenticatedUser,
    setRefreshToken,
    setUserToken,
    setLocalSession
  } = useAuth();

  useEffect(() => {
    setDispatcherToken();
    setExtractorToken();
    setDecisionToken();
    setSapientToken();
    setAuthenticatedUser();
    setRefreshToken();
    setUserToken();
    setLocalSession();
    localStorage.clear();
    document.body.classList.remove("dark");
    navigate('/login', { replace: true });
  }, [navigate, setDispatcherToken, setExtractorToken, setDecisionToken, setSapientToken, setAuthenticatedUser, setRefreshToken, setUserToken, setLocalSession]);

  return null;
};

export default UseLogout;