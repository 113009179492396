import { useEffect, useState, useRef, useCallback } from "react";
import { Client } from "paho-mqtt";
import { getTime } from "date-fns";
import ReconnectingWebSocket from "reconnecting-websocket";

const MQTT_BROKER_URL = process.env.REACT_APP_MQTT_BROKER_URL || null;
const MQTT_USERNAME = process.env.REACT_APP_MQTT_USERNAME || null;
const MQTT_PASSWORD = process.env.REACT_APP_MQTT_PASSWORD || null;
const PING_INTERVAL = 20000; // 25 seconds

const useMqtt = () => {
  const clientRef = useRef(null);
  const subscriptionsRef = useRef({});
  const pingIntervalRef = useRef(null);
  const [brokerClient, setBrokerClient] = useState(null);

  useEffect(() => {

    // Initialize WebSocket with automatic reconnection
    const ws = new ReconnectingWebSocket(MQTT_BROKER_URL.replace("mqtt://", "ws://").replace("mqtts://", "wss://"));

    ws.onopen = () => {
      clientRef.current = new Client(MQTT_BROKER_URL, "MINOTAUR-" + getTime(new Date()));

      // Connect the MQTT client
      clientRef.current.connect({
        userName: MQTT_USERNAME,
        password: MQTT_PASSWORD,
        onSuccess: () => {
          console.log("Broker connected..", clientRef.current.clientId);
          setBrokerClient(clientRef.current);

          // Start the heartbeat (ping) mechanism
          pingIntervalRef.current = setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
              ws.send("ping"); // Send a ping message over WebSocket
            }
          }, PING_INTERVAL);
        },
        onFailure: (error) => {
          console.error("Connection failed:", error);
        },
      });

      clientRef.current.onMessageArrived = (message) => {
        const callback = subscriptionsRef.current[message.topic];
        if (callback) callback(message.payloadString);
      };
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected.");
      clearInterval(pingIntervalRef.current);
    };

    return () => {
      if (clientRef.current && clientRef.current.isConnected()) {
        clientRef.current.disconnect();
        console.log("Broker disconnected..");
      }
      clearInterval(pingIntervalRef.current);
      ws.close();
    };
  }, []);

  const brokerSubscribe = useCallback((topic, callbackFunction) => {
    if (clientRef.current && clientRef.current.isConnected()) {
      clientRef.current.subscribe(topic, {
        onSuccess: () => {
          if (!subscriptionsRef.current[topic]) subscriptionsRef.current[topic] = callbackFunction;
        }
      });
    }
  }, []);

  const brokerPublish = useCallback((topic, payload, qos = 0, retained = false) => {
    if (clientRef.current && clientRef.current.isConnected()) {
      clientRef.current.publish(topic, payload, qos, retained);
    }
  }, []);

  return {
    brokerClient,
    brokerSubscribe,
    brokerPublish
  };
};

export default useMqtt;
